<template>
  <v-app>
    <v-app-bar
      color="#12acb8"
      dense
      clipped-left
      dark
      max-height="48px"
      id="app"
      v-if="!isPublicView"
      v-show="showNavbar"
    >
      <!--v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon-->
      <v-btn
        icon
        v-if="
          !userInfo ||
          $route.name == 'Index' ||
          (userInfo && userInfo.esMedico === false)
        "
      >
        <v-img
          src="@/assets/icon-telesalud-36x36.png"
          max-height="28px"
          max-width="28px"
        ></v-img>
      </v-btn>
      <v-btn v-else icon @click="drawer = true && $route.name != 'Index'">
        <v-badge color="red accent-4" overlap :content="appCnt" :value="appCnt">
          <v-icon v-if="userInfo">mdi-menu</v-icon>
          <v-img
            v-else
            src="@/assets/icon-telesalud-36x36.png"
            max-height="28px"
            max-width="28px"
          ></v-img>
          <template v-if="userInfo">
            <v-icon
              v-if="!configVal && containsAnyNew(configuracion)"
              color="green accent-2"
              class="ml-n10 mt-n6"
              >mdi-new-box</v-icon
            >
          </template>
        </v-badge>
      </v-btn>
      <v-toolbar-title style="cursor: pointer;">
        <b>MedicalManik en línea</b>
        <code class="ml-1 caption" v-if="$root.version" :title="'versión '+$root.fullVersion">v. {{$root.version}}</code>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu left bottom v-if="userInfo">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" title="Mi cuenta">
            <v-icon>mdi-card-account-details-star-outline</v-icon>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="400">
          <img
            :src="avatar"
            width="100"
            style="max-height: 150px"
            class="mt-2"
          />
          <v-card-subtitle class="pt-0 pb-2"  @click="goPanel('perfil')" style="cursor: pointer;">
            <b>{{userInfo.nombre}}</b>
          <br>
          <code>
            {{ userInfo.correo }}
          </code>
          </v-card-subtitle>
          <v-card-text class="text--primary">
            <div><b>Vigencia suscripción</b></div>
            <div>{{ userInfo.suscripcion }}</div>
          </v-card-text>

          <v-card-actions class="d-flex flex-column">
            <div v-show="!userInfo.grupo" class="py-1">
              <v-btn color="#07446a" text @click="comprar">
                <v-icon>mdi-credit-card-plus-outline</v-icon>
                Comprar
              </v-btn>
              <v-btn color="#07446a" text @click="historial">
                <v-icon>mdi-cart</v-icon>
                Historial
                <!-- https://app2.medicalmanik.com/compras/compras?at=ce6ee982-812e-4ea9-90c7-d009ed701cb5 -->
              </v-btn>
            </div>
            <v-btn
              color="#07446a"
              text
              title="Visitar Ecosistema Manik"
              @click="visitEM"
            >
              <v-icon>mdi-apps</v-icon>
              Ecosistema Manik
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-btn icon>
        <v-icon v-if="this.$route.name === 'Index'" @click="loginHome"
          >mdi-account-key-outline</v-icon
        >
        <v-icon
          @click="logout"
          v-if="$route.name != 'Room' && this.$route.name !== 'Index'"
          >mdi-logout</v-icon
        >
      </v-btn>
    </v-app-bar>
    <!--   dark adsolute temporary mini-variant  color="blue lighten-5"  -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      v-if="($route.name != 'Room' && userInfo) || !isPublicView"
    >
      <v-list nav dense>
        <v-list-group
          no-action
          v-if="userInfo"
          :title="`${userInfo.nombre}\n${userInfo.correo} (Mi perfil, curriculum vitae y presetador de servicios)`"
          ref="groupAccount"
        >
          <template v-slot:activator>
            <v-list-item-avatar>
              <v-avatar class="mt-2 grey">
                <img
                  v-if="userInfo.avatar"
                  :src="avatar"
                  :alt="userInfo.nombre"
                  width="20"
                  height="20"
                />
                <span v-else class="white--text headline text-uppercase">{{
                  userInfo.nombre.substr(0, 1)
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1">{{
                userInfo.nombre
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-left">{{
                userInfo.correo
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item title="Mi perfil" @click="goPanel('perfil')">
            <v-list-item-title class="text-left">Mi perfil</v-list-item-title>
          </v-list-item>
          <v-list-item title="Curriculum vitae" @click="goPanel('cv')">
            <v-list-item-title class="text-left"
              >Curriculum vitae</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            title="Configuración del prestador de servicio de salud y las CLUES"
            :to="{ name: 'ConfigPrestadorSalud' }"
          >
            <v-list-item-title class="text-left"
              >Prestador de servicios</v-list-item-title
            >
          </v-list-item>
        </v-list-group>
        <v-divider class="mb-1"></v-divider>
        <v-list-item-group v-model="group">
          <v-list-item
            key="home"
            :to="{ name: 'Home' }"
            title="Agenda (citas y eventos)"
            :class="vliClass('Agenda')"
          >
            <v-list-item-icon>
              <v-badge
                color="green"
                overlap
                :content="vliBdg('Agenda')"
                :value="vliBdg('Agenda')"
              >
                <v-icon>mdi-calendar-clock</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Agenda</v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="{ name: 'Pacientes' }"
            title="Registra ó busca pacientes"
            :class="vliClass('Agenda')"
          >
            <v-list-item-icon>
              <v-badge
                color="green"
                overlap
                :content="vliBdg('Agenda')"
                :value="vliBdg('Agenda')"
              >
                <v-icon>mdi-account-outline</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Pacientes</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Estadisticas' }" title="Mis estadísticas">
            <v-list-item-icon>
              <v-icon>mdi-chart-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left"
              >Mis estadísticas</v-list-item-title
            >
          </v-list-item>
          <!-- -->
          <v-list-item
            title="Citas de segundas opiniones"
            v-if="userInfo && userInfo.grupo"
            :to="{ name: 'SegundaOpinion' }"
            :class="getInformClass('segOpinion')"
          >
            <v-list-item-icon>
              <v-badge
                color="red accent-4"
                overlap
                :content="inform['segOpinion']"
                :value="inform['segOpinion']"
              >
                <v-icon>mdi-numeric-2-box-multiple-outline</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title class="text-left"
              >Segundas opiniones</v-list-item-title
            >
          </v-list-item>

          <!--  vlg inbox -->
          <v-list-group prepend-icon="mdi-cog-outline" :value="configVal">
            <template v-slot:activator>
              <v-list-item-title class="text-left mr-n3"
                >Configuración
                <v-icon
                  v-if="!configVal && containsAnyNew(configuracion)"
                  color="success"
                  >mdi-new-box</v-icon
                >
              </v-list-item-title>
            </template>
            <template v-for="(item, index) in configuracion">
              <!-- first w/children  next: no-children-->
              <template v-if="item['hide'] && item['hide'] === true"
                ><!--do-nothing--></template
              >
              <template
                v-else-if="
                  userInfo && userInfo.grupo && item.onlyIndividual === true
                "
              >
                <!-- not allowed -->
              </template>
              <v-list-group
                v-else-if="item.children"
                :key="index"
                sub-group
                no-action
                :value="item.expand"
                prepend-icon="mdi-chevron-down"
              >
                <v-list-item slot="activator" prepend-icon="mdi-chevron-down">
                  <v-list-item-title class="text-left mr-n3">
                    {{ item.title }}
                    <v-icon v-if="containsAnyNew(item)" color="success"
                      >mdi-new-box</v-icon
                    >
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="i"
                  @click="itemSelected(child)"
                  :title="child.tooltip ? child.tooltip : child.title"
                >
                  <v-list-item-title class="text-left ml-n4 mr-n4">
                    {{ child.title }}
                    <v-icon
                      v-if="child['isNew'] && child['isNew'] === true"
                      color="success"
                      class="ml-1"
                      >mdi-new-box</v-icon
                    >
                  </v-list-item-title>
                  <v-list-item-action v-if="child.icon"> </v-list-item-action>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item>
              </v-list-group>
              <v-list-item
                @click="itemSelected(item)"
                class="pl-7"
                :key="index"
                :title="item.tooltip ? item.tooltip : item.title"
                v-else
              >
                <v-list-item-action v-if="item.icon" class="mr-4">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-action>
                <v-list-item-title class="text-left mr-n3">
                  {{ item.title }}
                  <v-icon v-if="containsAnyNew(item)" color="success"
                    >mdi-new-box</v-icon
                  >
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <!--  -->
          <v-list-group :value="herramientas">
            <template v-slot:prependIcon>
              <v-list-item-icon class="mt-0">
                <v-badge
                  color="red accent-4"
                  overlap
                  :content="hsBadge"
                  :value="hsBadge"
                >
                  <v-icon>mdi-apps</v-icon>
                </v-badge>
              </v-list-item-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title class="text-left"
                >Herramientas</v-list-item-title
              >
            </template>
            <template v-for="(item, index) in herramientasArr">
              <template v-if="showIndividual(item.onlyIndividual)">
                <v-list-group
                  v-if="item.children"
                  :key="index"
                  sub-group
                  no-action
                  :value="item.expand"
                  prepend-icon="mdi-chevron-down"
                >
                  <v-list-item slot="activator" prepend-icon="mdi-chevron-down">
                    <v-list-item-title
                      class="text-left"
                      v-text="item.title"
                    ></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="`p_${index}child_${i}`"
                    @click="itemSelected(child)"
                    :title="child.tooltip ? child.tooltip : child.title"
                  >
                    <v-list-item-title
                      class="text-left body-2"
                      v-text="child.title"
                    ></v-list-item-title>
                    <v-list-item-action v-if="child.icon">
                      <v-icon v-text="child.icon"></v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-group>
                <v-list-item
                  @click="itemSelected(item)"
                  class="pl-7"
                  :key="index"
                  :title="item.tooltip ? item.tooltip : item.title"
                  v-else
                >
                  <v-list-item-action v-if="item.icon" class="mr-4">
                    <v-badge
                      v-if="item.infKey"
                      color="red accent-4"
                      overlap
                      :content="inform[item.infKey]"
                      :value="inform[item.infKey]"
                    >
                      <v-icon :title="item.title" v-text="item.icon"></v-icon>
                    </v-badge>
                    <v-icon v-else v-text="item.icon"></v-icon>
                  </v-list-item-action>
                  <v-list-item-title
                    class="text-left"
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </template>
          </v-list-group>
          <!--  vlg inbox -->
          <v-list-group no-action :value="expandInbox">
            <template v-slot:prependIcon>
              <v-list-item-icon class="mt-0">
                <v-badge
                  color="red accent-4"
                  overlap
                  :content="inboxBadge"
                  :value="inboxBadge"
                >
                  <v-icon>mdi-inbox-outline</v-icon>
                </v-badge>
              </v-list-item-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title class="text-left"
                >Manik inbox</v-list-item-title
              >
            </template>
            <template v-for="(item, idx) in inboxItems">
              <v-list-item
                :key="idx"
                :to="item.to"
                @click="handle_fn_call(item.fn_name)"
                :class="getInformClass(item.infKey)"
                :title="item.tooltip"
              >
                <v-list-item-title
                  class="text-left"
                  v-text="item.title"
                ></v-list-item-title>
                <v-list-item-icon>
                  <v-badge
                    color="red accent-4"
                    overlap
                    :content="item.infKey ? inform[item.infKey] : 0"
                    :value="item.infKey ? inform[item.infKey] : 0"
                  >
                    <v-icon :title="item.title" v-text="item.icon"></v-icon>
                  </v-badge>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>

          <v-divider class="mt-1 mb-1"></v-divider>
          <v-list-item :to="{ name: 'Blog' }">
            <v-list-item-icon>
              <v-icon>mdi-newspaper-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Blog</v-list-item-title>
          </v-list-item>
          <!--  -->
          <!-- Contacto MedicalManik-->
          <v-list-item :to="{ name: 'Contact' }">
            <v-list-item-icon>
              <v-icon>mdi-card-account-mail-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Contáctanos</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout" title="Salir">
            <v-list-item-icon>
              <v-icon title="Salir">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Salir</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="mt-0 pt-0 pl-0">
        <!-- Sin el pl-0 el iframe se RecetaManik se ve mal -->
        <router-view @message="message"></router-view>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
        <v-btn icon @click="visitEnterate(), (snackbar.visible = false)">
          Ver
        </v-btn>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-if="this.info && this.typePub == 1"
      v-model="info"
      transition="dialog-bottom-transition"
      max-width="800"
      @keydown:esc="info = false"
    >
      <v-card>
        <v-card-title>
          <v-row class="justify-end">
            <v-col>
              <v-img
                max-width="160px"
                @load="logoOk = true"
                src="@/assets/medicalmanik-logo.png"
              ></v-img>
            </v-col>
            <v-col cols="5">
              <h4>Los mejores especialistas</h4>
            </v-col>
            <v-col class="d-flex flex-row-reverse">
              <v-icon small @click="info = false">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <top :items="items"></top>
      </v-card>
    </v-dialog>
    <publicidad
      v-if="info && typePub == 2 && this.prueba"
      :publicidad="items"
      :info="info"
    ></publicidad>

    <v-snackbar v-model="sb.show" :color="sb.color" :timeout="sb.to" top>
      {{ sb.msg }}
      <v-btn color="white" text @click="sb.show = false">Enterado</v-btn>
    </v-snackbar>
    <v-footer
      absolute
      class="mt-2 font-weight-medium"
      color="cyan lighten-5"
      v-if="$route.name == 'Index'"
    >
      Copyright © {{ new Date().getFullYear() }} Datateam Consulting, S.A. de
      C.V. & MedicalManik - ManikHub
    </v-footer>
  </v-app>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.animate-bg {
  animation-name: gradient0;
  animation-delay: 0.5s;
  animation-duration: 7s;
}
@keyframes gradient0 {
  from {
    background-color: rgba(100, 149, 237, 0.6);
  }
  to {
    background-color: rgba(240, 248, 255, 0.6);
  }
  // f->CornflowerBlue  (100, 149, 237)
  // t->AliceBlue (240, 248, 255)
}
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

Verde: #12acb8
Azul: #07446a
Gris claro: #e6e6e6
Gris Oscuro: #666666 Fuentes

MiMédicoManik
-------------
Verde: #5CB65C
Azul: #057ABE

 */
</style>
<script>
const RX_OPTIONS = [
  {
    title: "Formato",
    icon: "mdi-surround-sound-2-0",
    to: "RecetaWeb",
    tooltip: "Formato para MedicalManik en línea",
    isNew: true,
  },
  { title: "Formato", icon: "mdi-prescription", to: "FormatoReceta" },
  {
    title: "Mensajes",
    icon: "mdi-note-text-outline",
    to: "MensajesReceta",
  },
];
const VALUES_BDG = {
  noVisitados: 0,
  preguntas: 0,
  segOpinion: 0,
  exportados: 0,
};
export default {
  components: {
    Top: () => import("@/components/TopFive"),
    Publicidad: () => import("@/components/Publicidad.vue"),
  },
  methods: {
    vliClass(t) {
      return this.optionBdg && this.optionBdg == t && this.drawer
        ? "animate-bg"
        : "";
    },
    vliBdg(t) {
      return this.optionBdg && this.optionBdg == t ? 1 : 0;
    },
    logout: function () {
      this.axios
        .get("/session/logout")
        .then(function () {})
        .catch(function (err) {})
        .then(
          function () {
            this.bye();
          }.bind(this)
        );
    },
    bye: function () {
      this.reset();
      this.$router.replace({ name: "Index" });
    },
    reset: function () {
      this.clearBdg();
      window.sessionStorage["mh-session-id"] = null;
      this.$store.commit("setUser", null);
      localStorage.mhSessionId = null;
      return null;
    },
    loginHome: () => {
      window.location.href = process.env.VUE_APP_SSO_URL;
    },
    historial: function () {
      window.open(
        process.env.VUE_APP_APP_URL +
          "/compras/compras?at=" +
          window.sessionStorage["mh-session-id"]
      );
    },
    comprar: function () {
      window.open(process.env.VUE_APP_MEDICAL_MANIK_URL + "/web/payPal/planes");
    },
    isBrowserCompatible: function () {
      // Opera 8.0+
      var isOpera =
        (!!window.opr && !!window.opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== "undefined";

      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && eval("safari.pushNotification"))
        );

      // Chrome 1 - 79
      var isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);

      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;
      return isOpera || isFirefox || (isChrome && !isEdgeChromium && !isBlink);
    },
    goPanel: function (path) {
      window.open(
        process.env.VUE_APP_PANEL_URL +
          `/${path}?at=` +
          window.sessionStorage["mh-session-id"]
      );
    },
    iniciarSesion: async function () {
      //this.$route.query.at aún no se setea en este punto cuando el router está en hash mode
      let at = this.getParameterByName("at");
      var mhsi = at ? at : window.sessionStorage["mh-session-id"];
      if (!mhsi || mhsi == "null") {
        this.$store.commit("setUser", null);
      } else {
        if (mhsi != window.sessionStorage["mh-session-id"]) {
          window.sessionStorage["mh-session-id"] = mhsi;
        }

        try {
          const resp = await this.axios.get("/session/login/" + mhsi);
          if (resp.data) {
            window.sessionStorage["mh-session-id"] = mhsi;
            localStorage.mhSessionId = mhsi;
            this.$store.commit("setUser", resp.data);
            this.$store.commit(
              "setRecetaV2",
              resp.headers["recetav2"] === "true"
            );
            this.setupRx(this.receta_v2());
          } else {
            mhsi = null;
          }
        } catch (err) {
          /*
          if (err.response.status === 402) {
            return;
          }
          alert(
            err.response.status +
              ": " +
              (err.response.data && err.response.data.message
                ? err.response.data.message
                : err.response.statusText)
          );*/
        }

        if (!mhsi || mhsi == "null") {
          // No hay sesión válida, entonces redireccionar a index.html
          this.bye();
        } else {
          // Cuando haya sesión válida, entonces ejecutar acciones propias de sesión válida
          setTimeout(() => this.getInfoForBadges(), 3000);
        }
      }
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    visitEnterate: function () {
      window.open(
        process.env.VUE_APP_MMM_URL + "/enterate/" + this.$store.state.user.uuid
      );
    },
    getInfoForBadges: function () {
      //Por alguna razón mh-session-id es igual a 'null' y no null
      if (this.userInfo)
        this.axios
          .get("/enterate/publicidad")
          .then(({ data }) => {
            this.inform.noVisitados =
              data && data.noVisitados ? data.noVisitados : 0;
            this.obtAdditionalBadges();
          })
          .catch((e) => {});
    },
    obtAdditionalBadges: function () {
      if (this.userInfo.grupo && this.userInfo.grupo > 0)
        setTimeout(() => {
          this.about2ndOpinions();
        }, 500);
      else
        setTimeout(() => {
          this.getExportadosInfo();
        }, 500);
      setTimeout(() => {
        this.bestDoctors();
      }, 1000);
      setTimeout(() => {
        this.getPreguntaInfo();
      }, 2000);
    },
    about2ndOpinions: function () {
      this.axios.get("/medico/pending-second-opinions").then(({ data }) => {
        this.inform.segOpinion = data ? data : 0;
      });
    },
    bestDoctors: function () {
      if (Math.floor(Math.random() * 100) > 90)
        this.axios.get("/medico/about-best-doctors").then(({ data }) => {
          if (data.list) this.items = data.list;
          this.typePub = data.type;
          this.info = data.show;
          this.prueba = data.prueba;
          if (!this.prueba && this.typePub == 2) {
            this.snackbar.title = "Mensajes pendientes (" + this.items.t + ")";
            this.snackbar.visible = true;
          }
        });
    },
    visitPregunta: function () {
      window.open(
        process.env.VUE_APP_MMM_URL +
          "/preguntas-por-responder/" +
          this.$store.state.user.uuid
      );
    },
    getPreguntaInfo: function () {
      //Por alguna razón mh-session-id es igual a 'null' y no null
      if (
        window.sessionStorage["mh-session-id"] &&
        window.sessionStorage["mh-session-id"] != "null"
      )
        setTimeout(() => {
          this.axios.get("/medico/questions-to-answer").then(({ data }) => {
            this.inform.preguntas = data ? data : 0;
          });
        }, 1500);
    },
    getExportadosInfo: function () {
      if (
        window.sessionStorage["mh-session-id"] &&
        window.sessionStorage["mh-session-id"] != "null"
      )
        this.axios
          .get("/exportar/pending-downloads")
          .then(({ data }) => (this.inform.exportados = data ? data : 0));
    },
    itemSelected: function (item) {
      if (item && item.to) {
        //console.log(item);
        let params = item.params ? item.params : {};
        this.$router.replace({ name: item.to, query: params });
        if (this.$router.currentRoute.name == item.to) {
          this.$nextTick(() => {
            window.location.reload();
          });
        }
      }
    },

    message: function (msg, level) {
      this.sb.color = level;
      this.sb.msg = msg;
      this.sb.show = true;
    },
    visitEM: function () {
      window.open(
        process.env.VUE_APP_PANEL_URL +
          "/?at=" +
          window.sessionStorage["mh-session-id"]
      );
    },
    clearBdg: function () {
      this.inform = VALUES_BDG;
      this.optionBdg = null;
    },
    handle_fn_call: function (function_name) {
      this[function_name]();
    },
    getInformClass: function (key) {
      return key && this.inform[key] && this.inform[key] > 0
        ? "animate-bg"
        : "";
    },
    showIndividual: function (onlyIndividual) {
      return onlyIndividual === true
        ? this.userInfo && !this.userInfo.grupo
        : true;
    },
    setupRx(v) {
      var arr = RX_OPTIONS;
      if (v === true) {
        //erase-oldone
        arr[0].icon = "mdi-prescription";
        arr[0].isNew = false;
        delete arr[0].isNew;
        arr.splice(1, 1);
      }
      this.configuracion[1].children = arr;
      this.configuracion[1].hide = false;
    },
    containsAnyNew(item) {
      if (item)
        for (let index = 0; index < item.length; index++) {
          const element = this.containsAnyNew(item[index]);
          if (element === true) {
            return true;
          }
        }
      if (item["isNew"] && item["isNew"] === true) return true;
      if (item.children) {
        var found = item.children.find((element) => {
          return (
            (element["isNew"] && element["isNew"] === true) ||
            this.containsAnyNew(element)
          );
        });
        if (found) return true;
      }
      return false;
    },
  },

  computed: {
    showNavbar: function () {
      return this.$root.showNavbar;
    },
    userInfo: function () {
      return this.$store.state.user;
    },
    avatar: function () {
      return this.userInfo.avatar
        ? `data:image/png;base64,${this.userInfo.avatar}`
        : `${process.env.VUE_APP_MMM_URL}/web/avatar?aii=${this.userInfo.uuid}`;
    },
    appCnt: function () {
      var cnt = this.optionBdg ? 1 : 0;
      Object.entries(this.inform).forEach(([, val]) => (cnt += val));
      return cnt;
    },
    rootDrawer: function () {
      return this.$root.drawer;
    },
    inboxBadge: function () {
      var cnt = 0;
      this.inboxItems.forEach(
        (e) =>
          (cnt += e.infKey && this.inform[e.infKey] ? this.inform[e.infKey] : 0)
      );
      return cnt;
    },
    exportadosCount: function () {
      return this.exportados;
    },
    hsBadge: function () {
      var cnt = 0;
      this.herramientasArr.forEach(
        (e) =>
          (cnt += e.infKey && this.inform[e.infKey] ? this.inform[e.infKey] : 0)
      );
      return cnt;
    },
    sb() {
      return this.$root.snackbar;
    },
    receta_v2() {
      return this.$store.state.recetaV2;
    },
    isPublicView:function(){
            return this.$route.path.indexOf('/public')>-1;
        }
  },
  watch: {
    rootDrawer(v) {
      if (v != null) {
        this.optionBdg = v;
        this.$nextTick(() => (this.drawer = true));
      } else {
        this.$root.drawer = null;
        this.optionBdg = null;
      }
    },
    drawer(v) {
      if (!v && this.optionBdg != null) this.optionBdg = null;
    },
    receta_v2(v) {
      this.setupRx(v);
    },
  },
  data: function () {
    return {
      items: [],
      info: false,
      typePub: null,
      confirm: false,
      drawer: false,
      optionBdg: null,
      inform: { noVisitados: 0, preguntas: 0, segOpinion: 0, exportados: 0 },
      group: null,
      configuracion: [
        {
          title: "Consultorios",
          expand: false,
          children: [
            {
              title: "Horarios",
              icon: "mdi-hospital-building",
              to: "Consultorios",
              tooltip: "Administración de horarios y ubicación",
            },
            {
              title: "Asistentes",
              icon: "mdi-account-multiple",
              to: "Asistentes",
              tooltip:
                "Registra tus asistentes y específica en qué agenda quieres que tengan acceso.",
            },
          ],
          onlyIndividual: true,
        },
        {
          icon: "mdi-clipboard-list",
          title: "Receta",
          expand: false,
          children: [],
          hide: true,
        },
        {
          icon: "mdi-clipboard-list",
          title: "Mis catálogos",
          expand: false,
          children: [
            {
              title: "Medicamentos",
              tooltip: "Mis medicamentos",
              icon: "mdi-pill",
              to: "MisCatalogos",
            },
            {
              title: "Estudios",
              tooltip: "Mis estudios",
              icon: "mdi-test-tube",
              to: "MisCatalogos",
              params: { op: "estudios" },
            },
            {
              title: "Diagnósticos de embarazo",
              tooltip: "Mis diagnósticos de embarazo",
              icon: "mdi-human-pregnant",
              to: "MisCatalogos",
              params: { op: "dx-emb" },
            },
          ],
        },
        {
          title: "Preferencias",
          tooltip: "Preferencias del sistema",
          icon: "mdi-tune-vertical",
          to: "preferencias",
        },
        {
          title: "Etiquetas",
          tooltip: "Editar etiquetas de exploración física",
          icon: "mdi-format-text-variant",
          to: "etiquetas",
        },
        {
          title: "Mis campos",
          tooltip: "Campos personalizados para la consulta o antecedentes",
          icon: "mdi-form-select",
          to: "campos",
          isNew: true,
        },
      ],
      expandInbox: false,
      configVal: false,
      inboxItems: [
        {
          title: "Entérate",
          icon: "mdi-newspaper-variant-multiple",
          fn_name: "visitEnterate",
          infKey: "noVisitados",
        },
        {
          title: "Responder preguntas",
          tooltip: "Responde dudas de pacientes",
          icon: "mdi-comment-outline",
          fn_name: "visitPregunta",
          infKey: "preguntas",
        },
      ],
      herramientas: false,
      herramientasArr: [
        {
          title: "Reportes",
          icon: "mdi-clipboard-list-outline",
          to: "Reportes",
        },
        {
          title: "Estadísticas",
          icon: "mdi-chart-line",
          to: "Statistics",
        },
        {
          title: "Negatoscopio",
          icon: "mdi-overscan",
          to: "negatoscopio",
        },
        {
          title: "Notificar cita",
          icon: "mdi-whatsapp",
          to: "WhatsApp",
        },
        {
          title: "Unificar expedientes",
          tooltip: "Unifica expedientes clínicos repetidos",
          icon: "mdi-set-merge",
          to: "UnificarExpedientesClinicos",
          onlyIndividual: true,
        },
        {
          icon: "mdi-folder-open-outline",
          title: "Exportar e importar",
          expand: false,
          onlyIndividual: true,
          children: [
            {
              title: "Exportar",
              tooltip: "Exportar",
              icon: "mdi-file-export",
              to: "Exportador",
            },
            {
              title: "Importar historial",
              tooltip: "Registrar pacientes y consultas atendidas al sistema",
              icon: "mdi-microsoft-excel",
              to: "Importador",
            },
          ],
        },
        {
          title: "Descargar exportados",
          tooltip: "Descargar documentos exportados",
          icon: "mdi-file-download-outline",
          to: "MisExportados",
          infKey: "exportados",
          onlyIndividual: true,
        },
        {
          title: "Bitácora de agenda",
          tooltip: "Bitácora de agenda",
          to: "bitacora-agenda",
          icon: "mdi-timeline-text-outline",
        },
        {
          title: "Próximos cumpleaños",
          tooltip: "Próximos cumpleaños",
          to: "birthdays",
          icon: "mdi-cake-variant-outline",
        },
        {
          title: "Oportunidad de campaña",
          tooltip: "Oportunidad de atraer clientes",
          to: "OportunidadCampania",
          icon: "mdi-bullhorn-variant-outline",
          onlyIndividual: true,
        },
      ],
      snackbar: {
        color: "warning",
        mode: "multi-line",
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false,
      },
      prueba: null,
    };
  },
  mounted() {
    if (!this.isBrowserCompatible) {
      this.message(
        "El sistema funciona con Firefox, Chrome u Opera. Tu navegdor no está soportado",
        "error"
      );
    }
    this.iniciarSesion(); // Cualquier otra operación debe estar dentro de esta función
  },
};
</script>
