import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:null,
    recetaV2:null,
  },
  mutations: {
    setUser(state,data){
      state.user=data
    },
    setRecetaV2(state,data){
      state.recetaV2=data
    }
  },
  actions: {
  },
  modules: {
  }
})
