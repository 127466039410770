import Vue from 'vue'
import axios from './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueRouter from 'vue-router';

Vue.config.productionTip = false
const ERR_MSG="err-msg"
var vue = new Vue({
  vuetify,
  router,
  axios,
  store,
  render: h => h(App),
  data: {
    drawer: null,
    showPregnancyTool: false,
    indexConsultaTool: null,
    dataConsultaTool: null,
    snackbar: { show: false, msg: "", color: "success", to: 3000 },
    showNavbar:true
  },
  computed: {
    version: function() { return process.env.VUE_APP_VERSION.split('.')[0] },
    fullVersion: function() { return process.env.VUE_APP_VERSION },
  }
});

window.axios.interceptors.request.use(function (config) {
  if (sessionStorage['mh-session-id']) {
    config.headers['mh-session-id'] = sessionStorage['mh-session-id'];
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  console.log(err);
  let msg = (err.response.data&&err.response.data.message?err.response.data.message:err.response.statusText);
  if(err.response.headers[ERR_MSG]){
    vue.snackbar.msg=err.response.headers[ERR_MSG];vue.snackbar.color="error";vue.snackbar.show=true;
  } else
    switch(err.response.status){
        case 401: // The request requires HTTP authentication.
            sessionStorage['mh-session-id'] = null;
            store.commit('setUser', null);
            localStorage.mhSessionId = null;
            window.location.href = process.env.VUE_APP_SSO_URL;
            break;
        case 402: // Payment required (according to HttpServletResponse)
            if(vue.$route.name!=='NotFound') {
                vue.$router.replace({ name: 'NotFound', params:{expired:true}});
            }
            break;
        case 403: // The server understood the request but refused to fulfill it.
            vue.$router.replace({ name: 'NotFound' });
            break;
        case 406:
            //alert( ' (' + err.response.status+') ' + err.response.data );            
            vue.snackbar.msg= ' (' + err.response.status+') ' + err.response.data;
            vue.snackbar.color="error";
            vue.snackbar.show=true;
            break;
        case 500: // Error inside the HTTP server which prevented it from fulfilling the request.
            alert('Error interno, comuníque al proveedor: ' + msg);
            break;
        default:
            alert('La petición que hizo no es correcta: ' + msg);
    }
  return Promise.reject(err);
});

vue.$mount('#app')

