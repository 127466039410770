import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue')
  },
  {
    path:'/pacientes',
    name:'Pacientes',
    component: () => import(/* webpackChunkName: "pacientes" */ '@/views/Pacientes.vue')
  },
  // {
  //   path:'/receta',
  //   name:'Receta',
  //   props:true,
  //   component: () => import(/* webpackChunkName: "receta" */ '@/views/Receta.vue')
  // },
  
  {
    path: '/home',
    name:'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Agenda.vue')
  },
  {
    path:'/consulta/:c/:p',
    name:'Consulta',
    component:()=>import(/* webpackChunkName: "consulta" */ '@/views/Consulta.vue'),
  },
  {
    path: '/room/:r',
    name:'Room',
    component: () => import(/* webpackChunkName: "room" */ '@/views/Room.vue')
  },
  {
    path: '/consultorios',
    name:'Consultorios',
    component: () => import(/* webpackChunkName: "consultorios" */ '@/views/Consultorios.vue')
  },
  {
    path: '/estadisticas',
    name:'Estadisticas',
    component: () => import(/* webpackChunkName: "consultorios" */ '@/views/Estadisticas.vue')
  },
  {
    path:'/not-found',
    name:'NotFound',
    component:NotFound,
    props: true
  },
  { path: "*", redirect: '/'  },
  {
    path: '/formatoReceta',
    name:'FormatoReceta',
    component: () => import(/* webpackChunkName: "consultorios" */ '@/views/FormatoReceta.vue')
  },
  {
    path: '/mis-catalogos',
    name:'MisCatalogos',
    component: () => import(/* webpackChunkName: "MisCatalogos" */ '@/views/MisCatalogos.vue')
  },
  {
    path: '/mensajes-receta',
    name:'MensajesReceta',
    component: () => import(/* webpackChunkName: "MensajesReceta" */ '@/views/MensajesReceta.vue')
  },
  {
    path: '/asistentes',
    name: 'Asistentes',
    component: () => import(/* webpackChunkName: "Asistentes" */ '@/views/Asistentes.vue')
  },
  {
    path: '/segunda-opinion',
    name: 'SegundaOpinion',
    component: () => import(/* webpackChunkName: "SegundaOpinion" */ '@/views/SegundaOpinion.vue')
  },  
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "Blog" */ '@/views/Blog.vue')
  },  
  {
    path: '/preferencias',
    name: 'preferencias',
    component: () => import('@/views/Preferencias.vue')
  }, 
  {
    path: '/reportes',
    name: 'Reportes',
    component: () => import(/* webpackChunkName: "Reportes" */ '@/views/Reportes.vue')
  },
  {
    path: '/exportados',
    name: 'MisExportados',
    component: () => import('@/components/MisExportados.vue')
  },
  {
    path: '/importador',
    name: 'Importador',
    component: () => import('@/views/Importador.vue')
  },
  {
    path: '/exportador',
    name: 'Exportador',
    component: () => import('@/views/Exportador.vue')
  },
  {
    path: '/merge',
    name: 'UnificarExpedientesClinicos',
    component: () => import('@/views/UnificarExpedientesClinicos.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Reportes" */ '@/views/Contact.vue')
  },
  {
    path: '/whatsapp',
    name: 'WhatsApp',
    component: () => import(/* webpackChunkName: "Reportes" */ '@/views/NotificarCitaWhatsApp.vue')
  },
  {
    path: '/negatoscopio',
    name: 'negatoscopio',
    component: () => import(/* webpackChunkName: "Reportes" */ '@/components/Negatoscopio.vue')
  },
  {
    path: '/etiquetas',
    name: 'etiquetas',
    component: () => import('@/views/Etiquetas.vue')
  },
  {
    path: '/config-prestador-salud',
    name:'ConfigPrestadorSalud',
    component: () => import('@/views/ConfigPrestadorServicioSalud.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('@/views/Statistics.vue')
  },
  {
    path: '/bitacora-agenda',
    name: 'bitacora-agenda',
    component: () => import(/* webpackChunkName: "BitacoraAgenda" */ '@/views/BitacoraAgenda.vue')
  },
  {
    path: '/bitacora-agenda-grupo/:si',
    name: 'bitacora-agenda-grupo',
    component: () => import(/* webpackChunkName: "BitacoraAgenda" */ '@/views/BitacoraAgenda.vue')
  }, 
  {
    path: '/oportunidad',
    name: 'OportunidadCampania',
    component: () => import('@/views/OportunidadCampania.vue')
  },
  {
    path: '/reporte-sis-grupo/:si/:fi/:ff/:mid/:cid/:type',
    name: 'reporte-sis-grupo',
    component: () => import(/* webpackChunkName: "ReporteSIS" */ '@/views/ReporteSIS.vue')
  },
  {
    path: '/reporte-vacunas-grupo/:si/:p',
    name: 'reporte-vacunas-grupo',
    component: () => import(/* webpackChunkName: "ReporteVacunasGrupo" */ '@/views/ReporteVacunasGrupo.vue')
  },
  {
    path: '/receta-web',
    name:'RecetaWeb',
    component: () => import(/* webpackChunkName: "RecetaWeb(v 2.0)" */ '@/views/RecetaWeb.vue')
  },
  {
    path: '/groups/receta-web',
    name:'GrupoRecetaWeb',
    component: () => import(/* webpackChunkName: "GrupoRecetaWeb(v 2.0)" */ '@/views/RecetaWeb.vue')
  },
  {
    path: '/campos',
    name: 'campos',
    component: () => import(/* webpackChunkName: "Campos" */'@/views/Campos.vue')
  },
  {
    path: '/groups/campos',
    name: 'GrupoCampos',
    component: () => import(/* webpackChunkName: "Campos" */ '@/views/Campos.vue')
  },
  {
    path: '/public/receta-web',
    name:'RecetaWeb',
    component: () => import(/* webpackChunkName: "RecetaWeb(v 2.0)" */ '@/views/RecetaWeb.vue')
  },
  {
    path: '/birthdays',
    name: 'birthdays',
    component: () => import(/* webpackChunkName: "BitacoraAgenda" */ '@/views/Birthdays.vue')
  },
]

const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })
export default router
