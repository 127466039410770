<template>
    <v-container>
        <v-card elevation="2" class="mx-auto" max-width="400" v-if="expired">
            <v-card-title>Suscripción vencida</v-card-title>
            <v-card-subtitle>No cuentas con una suscripción vigente</v-card-subtitle>
            <v-card-text>
            No te preocupes, tu información aún estará en nuestro servicio por 6 meses a partir del vencimiento,
            si deseas acceder a ella te invitamos a que adquieras uno de nuestros planes.
            </v-card-text>
            <v-card-actions>
                <v-btn
                        color="teal"
                        outlined
                        rounded
                        @click="buy"
                >
                        Ver planes
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-alert
          prominent
          type="error"
          align="left"
          border="left"
          max-width="600px"
          class="mx-auto"
          v-else
        >
          <v-row>
            <v-col class="grow">La operación que intenta ejecutar ya no está disponible o no tienes permitido accederla.</v-col>
            <v-col class="shrink">
              <v-btn to="/">Principal</v-btn>
            </v-col>
          </v-row>
        </v-alert>
    </v-container>

</template>
<script>
export default {
    name:'NotFound',
    props:{
        expired:{
            type:Boolean,
            default:false
        }
    },
    methods:{
        buy:function(){
            document.location.href=process.env.VUE_APP_MEDICAL_MANIK_URL+'/web/payPal/planes';
        }
    }
}
</script>